import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Header from '../components/Header'
import Footer from '../components/Footer'
import ContactSection from '../components/ContactSection'
import Seo from '../components/Seo'
import CookiePopUp from '../components/CookiePopUp'


export default function contactTemplate({ data }) {

  const { wpPage: { title, contact: { templateType, hasGoogleMap, googleMapSection, contactFormSection }, translations, language },
    allWp: { nodes: [settings] },
    allWpMenu } = data

  return (
    <div className="contact-template">
      <Seo title={title} lang={language.locale} />
      <Header menuData={allWpMenu} language={language} translations={translations} />
      <main>
        <ContactSection
          hasGoogleMap={hasGoogleMap}
          mapSectionData={googleMapSection}
          formSectionData={contactFormSection}
          templateType={templateType}
          language={language.locale}
          backendUrl={settings.generalSettings.url}
        />
      </main>
      <CookiePopUp language={language} />
      <Footer menuData={allWpMenu} language={language} footerOptionsPage={settings} />
    </div>
  )
}

contactTemplate.propTypes = {
  data: PropTypes.object.isRequired
}

export const contactTemplateQuery = graphql`
  query contactTemplateQuery($id: String!) {
    wpPage(id: {eq: $id}) {
      id
      language {
        locale
      }
      translations {
        uri
      }
      title
      contact {
        templateType
        hasGoogleMap
        contactFormSection {
          description
          fieldGroupName
          moreLinksTitle
          title
          contactForm {
            description
            disclaimer
            fieldGroupName
            title
            reasonsForContact {
              email
              reason
            }
          }
          additionalContacts {
            emails {
              email
              fieldGroupName
            }
            phones {
              fieldGroupName
              phone
            }
          }
        }
        googleMapSection {
          sectionTitle
          fieldGroupName
          googleMap {
            latitude
            longitude
            zoom
          }
          contactDetails {
            addressInfo {
              title
              info
              icon {
                file: localFile {
                  childImageSharp {
                    fluid {
                    ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            emailInfo {
              title
              info
              icon {
                file: localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            phoneInfo {
              title
              info
              icon {
                file: localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            officeHours {
              title
              info
              icon {
                file: localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allWp {
      nodes {
        ...getOptionsPageFooterData
        generalSettings {
          url
        }
      }
    }
    allWpMenu {
      ...getMenus
    }
  }
`
